import {Options, Vue} from "vue-class-component";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import {usersService} from "@services/users.service";

import {DynamicTable} from "@components";

import {FilterMatchMode} from "primevue/api";
import {Prop, Watch} from "vue-property-decorator";
import {CompanyTypeEnum} from "@/model/enums/CompanyTypeEnum";
import {CompaniesRoutesEnum} from "../router";
import {Company} from "@/model/api/Company";
import {companiesService} from "@services/companies.service";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import Table from "@components/AppTable/Table";
import moment from "moment";
import {fileDownload} from "@/utils/utils";

@Options({
  name: "ListPage",

  components: {
    DynamicTable
  },

  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith('BROKERS')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_BROKERS);
    }
    if (from.name && !(from.name as string).startsWith('CUSTOMERS')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_CUSTOMERS);
    }
    if (from.name && !(from.name as string).startsWith('CLIENTS')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_CLIENTS);
    }

    next();
  },
  beforeRouteLeave() {
    if (usersService?.cancelPendingRequests) {
      usersService.cancelPendingRequests();
    }
  },
})
export default class ListPage extends Vue {
  @Prop()
  readonly type!: CompanyTypeEnum;

  @Watch('type')
  private onTypeChange(val) {
    this.resetTable()
  }

  filters: any = null;
  exporting: boolean = false;
  tableKey = 0;

  get service() {
    return companiesService;
  }

  get stateKey() {
    return SessionStorageEnum[`TABLE_STATE_${CompanyTypeEnum[this.type]}S`]
  }

  get newRoute() {
    const route: any = {
      params: { companyId: 'new' }
    }
    switch (this.type) {
      case CompanyTypeEnum.CUSTOMER:
        route.name = CompaniesRoutesEnum.CUSTOMER_DETAIL
        break
      case CompanyTypeEnum.CLIENT:
        route.name = CompaniesRoutesEnum.CLIENT_DETAIL
        break
      default:
        // CompanyTypeEnum.BROKER
        route.name = CompaniesRoutesEnum.BROKER_DETAIL
        break
    }
    return route
  }

  async onExport() {

    if(!this.imAdmin){
      return
    }

    let type
    switch (CompanyTypeEnum[this.type]) {
      case 'BROKER':
        type = 'brokers'
        break
      case 'CUSTOMER':
        type = 'customers'
        break
      default:
        type = 'clients'
        break
    }

    try {
      const filters = (this.$refs.table as Table).filters;

      this.exporting = true

      const response = await companiesService.exportFile(filters, type);

      this.exporting = false

      const fileName = `${type}_export_${moment().format('YYYY_MM_DD')}`;

      fileDownload(
          fileName,
          response,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    } catch (error) {
      console.error(error);
      this.exporting = false
      this.$errorMessage("Export failed");
    }
  }

  get imAdmin() {
    return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
  }

  get isNewVisible() {
    return !(this.type == CompanyTypeEnum.CUSTOMER && authStore.getters.me.role == UserRoleEnum.CUSTOMER);
  }

  get cols() {
    return [
      {
        field: 'business_name',
        header: "Business name"
      },
      {
        field: 'business_address',
        header: "Business address"
      },
      {
        field: 'business_city',
        header: "Business city"
      },
      {
        field: 'business_country',
        header: "Business country"
      },
      {
        field: 'email',
        header: "Email"
      },
      {
        field: 'phone',
        header: "Phone"
      }
    ] as {
      field?: keyof Company,
      [key: string]: any
    }[]
  }

  goToDetail(company: Company) {

    const route: any = {
      params: { companyId: company.id }
    }
    switch (this.type) {
      case CompanyTypeEnum.CUSTOMER:
        route.name = CompaniesRoutesEnum.CUSTOMER_DETAIL
        break
      case CompanyTypeEnum.CLIENT:
        route.name = CompaniesRoutesEnum.CLIENT_DETAIL
        break
      default:
        // CompanyTypeEnum.BROKER
        route.name = CompaniesRoutesEnum.BROKER_DETAIL
        break
    }
    this.$router.push(route)
  }

  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },

      type: {
        value: this.type,
        matchMode: FilterMatchMode.EQUALS
      },

      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      business_name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      phone: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      business_address: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      business_city: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      business_country: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    };
    if (this.type == CompanyTypeEnum.BROKER || this.type == CompanyTypeEnum.CLIENT) {
      this.filters['customer.name'] = {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    }
  }

  created() {
    this.initFilter();
  }

  get title() {
    return this.$t(`company.type_${this.type}_plural`);
  }

  private resetTable() {
    this.initFilter();
    this.tableKey++;
  }
}
