import { BaseApiModel } from "@/model/common/BaseApiModel";
import { CompanyTypeEnum } from "../enums/CompanyTypeEnum";

export class Company extends BaseApiModel {
    type: CompanyTypeEnum;
    name: string;
    customer?: any;
    customers?: any[];
    customer_id?: number;
    business_name: string;
    business_address?: string;
    business_city: string;
    business_zip: string;
    business_country?: string;
    phone: string;
    email: string;
    note?: string;
    trucks_num?: number;

    doc?: File;
    doc_path_url?: string;

    companyLogo?: File;
    logo_image_path_url?: string;

    insurance_expiry?: Date;
    insurance?: string;


}
