import {Company} from "@/model/api/Company";
import {CompanyTypeEnum} from "@/model/enums/CompanyTypeEnum";
import {companiesService} from "@services/companies.service";
import {Options, Prop, Vue} from "vue-property-decorator";
import {
    AppTable,
    CompanyAutocomplete
} from "@components";
import {CompaniesRoutesEnum} from "@/modules/companies/router";
import {classToPlain} from "class-transformer";
import moment from "moment";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";

@Options({
    components: {
        CompanyAutocomplete,
        AppTable
    }
})
export default class CompanyPage extends Vue {
    @Prop() readonly companyId!: string;

    requiredFields: any[] = [
        {
            key: 'business_name',
            label: 'business name'
        }
    ];

    company: Company = null;

    get isNew() {
        return this.companyId === 'new';
    }

    get service() {
        return companiesService;
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
    }

    // get canDelete() {
    //     return !((this.company.type == CompanyTypeEnum.BROKER || this.company.type == CompanyTypeEnum.CUSTOMER) && authStore.getters.me.role == UserRoleEnum.CUSTOMER);
    // }

    get backRoute() {
        if((<string>this.$route.name).includes(CompaniesRoutesEnum.CUSTOMER_PREFIX)){
            return {name: CompaniesRoutesEnum.CUSTOMERS_LIST}
        } else if((<string>this.$route.name).includes(CompaniesRoutesEnum.BROKER_PREFIX)){
            return {name: CompaniesRoutesEnum.BROKERS_LIST}
        } else {
            return {name: CompaniesRoutesEnum.CLIENTS_LIST}
        }
    }

    get title() {
        const prefix = this.isNew ? "New" : "Edit";
        if((<string>this.$route.name).includes(CompaniesRoutesEnum.CUSTOMER_PREFIX)){
            return `${prefix} Customer`
        } else if((<string>this.$route.name).includes(CompaniesRoutesEnum.BROKER_PREFIX)){
            return `${prefix} Broker`
        } else {
            return `${prefix} Client`
        }
    }

    private async uploadFiles(companyId: number, payload) {
        return await companiesService.uploadFiles(
            companyId, payload
        )
    }

    onSave() {
        this.$waitFor(
            async () => {

                let areThereAnyErrors: boolean

                this.requiredFields.forEach((field) => {
                    if(this.company[field.key] === null || this.company[field.key] === undefined || this.company[field.key] === ''){
                        this.$errorMessage(`Field ${field.label} is required`);
                        areThereAnyErrors = true
                    }
                })

                if(areThereAnyErrors) return

                const plainShift = classToPlain(this.company);

                let insurance_expiry = plainShift['insurance_expiry']
                if(insurance_expiry && insurance_expiry instanceof Date) {
                    insurance_expiry = moment(insurance_expiry).format('YYYY-MM-DD')
                }

                const isLogoToUpload = [
                    this.company.companyLogo,
                ].some(x => !!x);

                const isDocToUpload = [
                    this.company.doc,
                ].some(x => !!x);

                if (this.isNew) {
                    const createdCompany = await companiesService.create(plainShift as any);

                    if (isLogoToUpload) {
                        await this.uploadFiles(createdCompany.id, {logo: this.company.companyLogo});
                        this.$successMessage("Files uploaded")
                    }

                    if (isDocToUpload) {
                        await this.uploadFiles(createdCompany.id, {doc: this.company.doc});
                        this.$successMessage("Files uploaded")
                    }

                    if((this.isBroker || this.isClient) && this.company.customers.length){
                        const promises = []
                        this.company.customers.forEach((customer) => {
                            promises.push(companiesService.addCustomerToCompany(
                                customer.id,
                                this.isBroker ? 'broker_id' : 'client_id',
                                createdCompany.id));
                        })
                        await Promise.all(promises)
                        this.$successMessage(`Customers successfully added to ${this.isBroker ? 'Broker' : 'Client'}`);
                    }
                } else {
                    await companiesService.updatePatch(plainShift as any);

                    if (isLogoToUpload) {
                        await this.uploadFiles(+this.companyId, {logo: this.company.companyLogo});
                        this.$successMessage("Files uploaded")
                    }

                    if (isDocToUpload) {
                        await this.uploadFiles(+this.companyId, {doc: this.company.doc});
                        this.$successMessage("Files uploaded")
                    }
                }
                this.$router.replace(this.backRoute).then(() =>
                    this.$successMessage("Success")
                );
            },
            this.$t('Operation failed')
        );
    }

    onDelete() {
        this.$confirmMessage("Are you sure?")
            .then(r => {
                if (r) {
                    this.deleteCompany();
                }
            })
    }

    private deleteCompany() {
        this.$waitFor(
            async () => {
                await companiesService.deleteBySystem(this.company);

                this.$router.replace(this.backRoute)
                    .then(() =>
                        this.$successMessage("Company successfully deleted")
                    );
            },

            "Deleting Company failed"
        )
    }

    async _loadCompany() {
        return this.$waitFor(
            async () => {
                this.company = await companiesService.getById(+this.companyId);
            },
            "Company not found"
        )
    }

    get tabs() {
        let i = 0;

        const companyType = (<string>this.$route.name).split('_')[0]

        const tabs = [
            {
                label: "Company data",
                icon: 'fas fa-clipboard-list',
                to: { name: `${companyType}_DETAIL` },
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            },
        ];

        if(!this.isNew){
            tabs.push({
                label: "Company invoices",
                icon: 'fas fa-money-bill',
                to: { name: `${companyType}_INVOICES` },
                class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
            })
        }

        return tabs
    }

    get isBroker(){
        return this.company.type === CompanyTypeEnum.BROKER
    }

    get isClient(){
        return this.company.type === CompanyTypeEnum.CLIENT
    }

    async mounted() {

        if (this.isNew) {
            this.company = new Company();
            switch (this.$route.name) {
                case CompaniesRoutesEnum.CUSTOMER_DETAIL:
                    this.company.type = CompanyTypeEnum.CUSTOMER
                    break
                case CompaniesRoutesEnum.CLIENT_DETAIL:
                    this.company.type = CompanyTypeEnum.CLIENT
                    break
                default:
                    // CompaniesRoutesEnum.BROKER_DETAIL
                    this.company.type = CompanyTypeEnum.BROKER
                    break
            }
            if(this.isClient){
                this.requiredFields.push({
                    key: 'companyLogo',
                    label: 'company logo'
                })
            }
        } else {
            await this._loadCompany();
        }


    }

}
