import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page--limited card" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "p-d-flex p-ai-center" }
const _hoisted_4 = { class: "p-d-flex p-ai-center" }
const _hoisted_5 = { class: "p-p-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: _ctx.backRoute }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_Button, {
              icon: "pi pi-arrow-left",
              class: "p-mb-0"
            }, null, 512), [
              [
                _directive_tooltip,
                _ctx.$t('back'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createVNode(_component_Button, {
          icon: "pi pi-save",
          class: "p-mr-1",
          onClick: _ctx.onSave,
          disabled: _ctx.requestPending
        }, null, 8, ["onClick", "disabled"]), [
          [
            _directive_tooltip,
            _ctx.$t('save'),
            void 0,
            { bottom: true }
          ]
        ]),
        (_ctx.company?.id && _ctx.imAdmin)
          ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
              key: 0,
              icon: "pi pi-trash",
              class: "p-button-danger",
              onClick: _ctx.onDelete,
              disabled: _ctx.requestPending
            }, null, 8, ["onClick", "disabled"])), [
              [
                _directive_tooltip,
                _ctx.$t('delete'),
                void 0,
                { bottom: true }
              ]
            ])
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_TabMenu, { model: _ctx.tabs }, null, 8, ["model"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              companyId: _ctx.companyId,
              company: _ctx.company
            }, null, 8, ["companyId", "company"]))
          ], 1024))
        ])
      ]),
      _: 1
    })
  ]))
}