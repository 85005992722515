import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { plainToClass } from 'class-transformer';
import { CRUD } from './base/crud';
import { InvoiceMovement } from "@/model/api/Invoice";

class InvoicesService extends CRUD<InvoiceMovement, any> {
    readonly endPoint = `invoices`;

    public async index(params?: any): Promise<PaginatedResponse<InvoiceMovement>> {
        const response = await super.index(params);

        response.data = plainToClass(InvoiceMovement, response.data);

        return response;
    }

    public async downloadInvoices(id: number) {
        return this.get<any>(`${this.endPoint}/${id}/download`, { responseType: 'arraybuffer' }, true);
    }

}

export const invoicesService = new InvoicesService();



