import {Company} from "@/model/api/Company";
import {CompanyTypeEnum} from "@/model/enums/CompanyTypeEnum";
import {companiesService} from "@services/companies.service";
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import {CompaniesRoutesEnum} from "../../router";
import {AppTable, CompanyAutocomplete, FileInput, ImageInput} from "@components";
import {FilterMatchMode} from "primevue/api";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";


@Options({
    components: {
        CompanyAutocomplete,
        AppTable,
        ImageInput,
        FileInput
    }
})
export default class DetailPage extends Vue {
    @Prop() readonly companyId!: string;

    @Prop() readonly company!: Company;

    filters: any = null;

    selectedCustomer: any = null;

    get isNew() {
        return this.companyId === 'new';
    }

    get service() {
        return companiesService;
    }

    get companyType() {
        switch (this.$route.name) {
            case CompaniesRoutesEnum.CUSTOMER_DETAIL:
                return CompanyTypeEnum.CUSTOMER
            case CompaniesRoutesEnum.CLIENT_DETAIL:
                return CompanyTypeEnum.CLIENT
            default:
                // CompaniesRoutesEnum.BROKER_DETAIL
                return CompanyTypeEnum.BROKER
        }
    }


    canDeleteCompany(id){
        return authStore.getters.me.role !== UserRoleEnum.CUSTOMER || authStore.getters.me.customer_id === id
    }

    get isBroker() {
        return this.companyType == CompanyTypeEnum.BROKER;
    }

    get isClient() {
        return this.companyType == CompanyTypeEnum.CLIENT;
    }

    get isCustomer() {
        return this.companyType == CompanyTypeEnum.CUSTOMER;
    }

    onCustomerSelect(customer: any) {
        if (customer) {
            this.company.customer_id = customer.id;
        } else {
            this.company.customer = null;
            this.company.customer_id = null;
        }
    }

    addCustomer(selectedCustomer: any){
        if(selectedCustomer && !this.company.customers.find((customer) => customer.id === selectedCustomer.id)){
            if(!this.isNew){
                this.$waitFor(
                    async () => {
                        await companiesService.addCustomerToCompany(
                            selectedCustomer.id,
                            this.isBroker ? 'broker_id' : 'client_id',
                            this.company.id);
                        this.company.customers.push(selectedCustomer)
                    }
                );
            } else {
                this.company.customers.push(selectedCustomer)
            }
        }
        this.$nextTick(() => {
            this.selectedCustomer = null
        })
    }

    onRemove(selectedCustomer: any){
        if(!this.isNew){
            this.$waitFor(
                async () => {
                    await companiesService.removeCustomerFromCompany(
                        selectedCustomer.id,
                        this.isBroker ? 'broker_id' : 'client_id',
                        this.company.id);
                    this.company.customers = this.company.customers.filter((customer) => selectedCustomer.id !== customer.id);
                }
            );
        } else {
            this.company.customers = this.company.customers.filter((customer) => selectedCustomer.id !== customer.id);
        }
    }

    get CUSTOMER_TYPE() {
        return CompanyTypeEnum.CUSTOMER
    }

    goToDetail(broker: any) {
        const routeData = this.$router.resolve({
            name: CompaniesRoutesEnum.BROKER_DETAIL,
            params: {companyId: broker.id}
        });
        window.open(routeData.href, '_blank');
    }

    @Watch('company')
    private async onCompanyChange(company) {
        if (company && !this.isNew) {
            if (company.type === CompanyTypeEnum.CUSTOMER) {
                this.initFilter()
            } else {
                this.company.customers = await companiesService.getCompanyCustomers(+this.companyId);
            }
        } else if(this.isNew && (
            company.type === CompanyTypeEnum.BROKER ||
            company.type === CompanyTypeEnum.CLIENT
        )){
            this.company.customers = []
        }
    }


    private initFilter() {
        this.filters = {
            type: {
                value: CompanyTypeEnum.BROKER,
                matchMode: FilterMatchMode.CONTAINS
            },
            customer_id: {
                value: this.company.id,
                matchMode: FilterMatchMode.EQUALS
            }
        };
    }

}
